<template>
  <div class="container-fluid h-100 d-flex flex-column scrollable-table p-0">
    <div class="text-center global-padding pb-3">
      <h2>{{ $t("WAVE_SELECTION_TITLE") }}</h2>
      <div class="text-center">
        <p>{{ $t("WAVE_SELECTION_SUBTITLE") }}</p>
        <p class="break-i18n">{{ $t("GREETINGS") }}</p>
      </div>
    </div>

    <div class="row justify-content-around global-padding pb-2">
      <div class="col-6">
        <div class="card text-center">
          <div class="card-body">
            <h5 class="card-title">Sales On-site</h5>
            Possible scenarios to choose from:
            <div class="row">
              <ul class="list-group list-group-flush col-4">
                <li class="list-group-item">{{ $t("CSV_BEV") }}</li>
                <li class="list-group-item">{{ $t("VIRTUAL_CONSULTATION") }}</li>
                <li class="list-group-item">{{ $t("CSV") }}</li>
                <li class="list-group-item">{{ $t("CSV_PHEV") }}</li>
              </ul>
              <ul class="list-group list-group-flush col-4">
                <li class="list-group-item">{{ $t("STOCK_CAR_BEV") }}</li>
                <li class="list-group-item">{{ $t("STOCK_CAR") }}</li>
                <li class="list-group-item">{{ $t("STOCK_CAR_PHEV") }}</li>
                <li class="list-group-item">{{ $t("UV_BEV") }}</li>
                <li class="list-group-item">{{ $t("UV_BEV_VIRTUAL") }}</li>
              </ul>
              <ul class="list-group list-group-flush col-4">
                <li class="list-group-item">{{ $t("UV") }}</li>
                <li class="list-group-item">{{ $t("UV_ICE_VIRTUAL") }}</li>
                <li class="list-group-item">{{ $t("UV_ICE_VIRTUAL_OMNICHANNEL") }}</li>
                <li class="list-group-item">{{ $t("UV_PHEV") }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card text-center">
          <div class="card-body p-1">
            <h5 class="card-title">Sales Off-site</h5>
            Possible way of contacts to choose from:
            <ul class="list-group list-group-flush">
              <li class="list-group-item">E-Mail</li>
              <li class="list-group-item">Contact form</li>
              <li class="list-group-item">Call</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive flex-full-height router-view-scrollable-content mb-3">
      <table class="table">
        <thead class="table-light">
          <tr>
            <th scope="col">{{ $t("WAVE") }}</th>
            <th scope="col">{{ $t("STATUS") }}</th>
            <th scope="col">{{ $t("ONSITE_CENTRAL") }}</th>
            <th scope="col">{{ $t("OFFSITE_CENTRAL") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="wave of waves" :key="wave.id">
            <td class="align-middle">
              <p class="mb-0">{{ wave.name }}</p>
            </td>

            <td class="align-middle">
              <p
                class="mb-0 d-flex align-items-center"
                v-if="wave.status === 'Configure'"
              >
                <img class="icon" :src="icon_configure" />
                {{ $t("CONFIGURE_STATE") }}
              </p>
              <p
                class="mb-0 d-flex align-items-center"
                v-if="wave.status === 'Running'"
              >
                <img class="icon" :src="icon_running" />
                {{ $t("RUNNING_STATE") }}
              </p>
              <p
                class="mb-0 d-flex align-items-center"
                v-if="wave.status === 'Closed'"
              >
                <img class="icon" :src="icon_closed_done" />
                {{ $t("CLOSED_STATE") }}
              </p>
            </td>

            <td class="align-middle">
              {{ `${wave.onSiteCentralFinished}/${wave.onSiteCentral}` }}
              <i
                class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
              >
                <span class="tooltiptext" style="top: 100%">
                  <ul>
                    <li>
                      {{
                        $t("WAVE_OVERVIEW_ONSITE_TOOLTIP", {
                          done: wave.onSiteCentralFinished,
                        })
                      }}
                    </li>
                    <li>
                      {{ $t("WAVE_OVERVIEW_DONE_EXPLAIN_TOOLTIP") }}
                    </li>
                  </ul>
                </span>
              </i>
            </td>

            <td class="align-middle">
              {{ `${wave.offSiteCentralFinished}/${wave.offSiteCentral}` }}
              <i
                class="bi bi-info-circle-fill ms-1 custom-tooltip tooltip-middle-width"
              >
                <span class="tooltiptext" style="top: 100%">
                  <ul>
                    <li>
                      {{
                        $t("WAVE_OVERVIEW_OFFSITE_TOOLTIP", {
                          done: wave.offSiteCentralFinished,
                        })
                      }}
                    </li>
                    <li>
                      {{ $t("WAVE_OVERVIEW_DONE_EXPLAIN_TOOLTIP") }}
                    </li>
                  </ul>
                </span>
              </i>
            </td>

            <td>
              <button
                type="button"
                class="btn btn-primary w-100"
                v-if="wave.status === 'Configure'"
                data-jp="start-wave"
                @click="selectWave(wave)"
              >
                {{ $t("START") }}
              </button>
              <button
                type="button"
                class="btn btn-secondary w-100"
                data-jp="start-wave"
                v-if="wave.status === 'Running'"
                @click="selectWave(wave)"
              >
                {{ $t("RESUME") }}
              </button>
              <button
                type="button"
                class="btn btn-secondary w-100"
                v-if="wave.status === 'Closed' || wave.status === 'Done'"
                @click="selectWave(wave)"
              >
                {{ $t("SUMMARY") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row no-gutters mb-3 global-padding">
      <div class="col-6 d-flex justify-content-start">
        <button
          class="btn btn-secondary"
          @click="
            $router.push({
              path: `/projects`,
            })
          "
        >
          {{ $t("PREVIOUS") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaveSelection",
  async setup() {
    return {
      icon_running: require("../assets/icons/running.svg"),
      icon_configure: require("../assets/icons/configure.svg"),
      icon_closed_done: require("../assets/icons/closed_done.svg"),
    };
  },
  computed: {
    waves() {
      return this.$store.getters["waves/getWaves"];
    },
  },
  methods: {
    async selectWave(wave) {
      const projectGuid = this.$route.params.projectGuid;
      await this.$router.push({
        path: `/projects/${projectGuid}/waves/${wave.guid}/general-setup`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.market-select {
  width: max-content;
}
</style>
